// Generated by Framer (8289486)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/xUCuNdICT7LqG93nhqbM/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {LB1xZeamS: {hover: true}};

const cycleOrder = ["LB1xZeamS"];

const variantClassNames = {LB1xZeamS: "framer-v-1otm3qh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "LB1xZeamS", link: cDJmIjeZl, title: cMKwo8QYh = "اكتشف المزيد", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "LB1xZeamS", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-PzEO8", classNames)} style={{display: "contents"}}>
<Link href={cDJmIjeZl}><motion.a {...restProps} className={`${cx("framer-1otm3qh", className)} framer-1ajr47p`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"LB1xZeamS"} ref={ref} style={{backgroundColor: "var(--token-bd573de2-a99b-47a0-86f9-5c1241a6fd64, rgb(0, 71, 187))", borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200, ...style}} transition={transition} variants={{"LB1xZeamS-hover": {backgroundColor: "rgb(107, 125, 239)"}}} {...addPropertyOverrides({"LB1xZeamS-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1bhspyh-container"} layoutDependency={layoutDependency} layoutId={"OWcdGHSJL-container"} transition={transition}><Phosphor color={"var(--token-d59b9de7-914a-416b-b35c-0bc1e3fd3641, rgb(255, 255, 255)) /* {\"name\":\"White\"} */"} height={"100%"} iconSearch={"House"} iconSelection={"ArrowLeft"} id={"OWcdGHSJL"} layoutId={"OWcdGHSJL"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0lCTSBQbGV4IFNhbnMgQXJhYmljIE1lZGl1bQ==", "--framer-font-family": "\"IBM Plex Sans Arabic Medium\", sans-serif", "--framer-font-size": "18px", "--framer-letter-spacing": "-0.5px", "--framer-line-height": "1.5em", "--framer-text-color": "var(--extracted-r6o4lv)"}}>اكتشف المزيد</motion.p></React.Fragment>} className={"framer-ll3lud"} data-framer-name={"Read More"} fonts={["CUSTOM;IBM Plex Sans Arabic Medium"]} layoutDependency={layoutDependency} layoutId={"TlIK95KwQ"} style={{"--extracted-r6o4lv": "var(--token-d59b9de7-914a-416b-b35c-0bc1e3fd3641, rgb(255, 255, 255)) ", "--framer-paragraph-spacing": "0px"}} text={cMKwo8QYh} transition={transition} verticalAlignment={"center"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-PzEO8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PzEO8 .framer-1ajr47p { display: block; }", ".framer-PzEO8 .framer-1otm3qh { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 18px; height: 44px; justify-content: center; overflow: visible; padding: 18px 32px 18px 32px; position: relative; text-decoration: none; width: 177px; }", ".framer-PzEO8 .framer-1bhspyh-container { flex: none; height: 28px; position: relative; width: 28px; }", ".framer-PzEO8 .framer-ll3lud { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", ".framer-PzEO8 .framer-v-1otm3qh .framer-1otm3qh { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-PzEO8 .framer-1otm3qh { gap: 0px; } .framer-PzEO8 .framer-1otm3qh > * { margin: 0px; margin-left: calc(18px / 2); margin-right: calc(18px / 2); } .framer-PzEO8 .framer-1otm3qh > :first-child { margin-left: 0px; } .framer-PzEO8 .framer-1otm3qh > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 177
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"EZcXYdSn3":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"cDJmIjeZl":"link","cMKwo8QYh":"title"}
 */
const FramerPsr7sUani: React.ComponentType<Props> = withCSS(Component, css, "framer-PzEO8") as typeof Component;
export default FramerPsr7sUani;

FramerPsr7sUani.displayName = "Ar/Button ???";

FramerPsr7sUani.defaultProps = {height: 44, width: 177};

addPropertyControls(FramerPsr7sUani, {cDJmIjeZl: {title: "Link", type: ControlType.Link}, cMKwo8QYh: {defaultValue: "اكتشف المزيد", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerPsr7sUani, [{family: "IBM Plex Sans Arabic Medium", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/Psr7sUani:default", url: "assets/c5ApvwGMskEGuIXW8X0BN1liRA.ttf"}, url: new URL("assets/c5ApvwGMskEGuIXW8X0BN1liRA.ttf", import.meta.url).href}, ...PhosphorFonts])